<template>
  <v-card
    color="#F4F9FF"
    style="display:flex; flex-direction: row; height: 100%; width: 100%"
  >
    <v-layout style="display: flex; flex-direction: column;">
      <label class="item-title">Event Home Images</label>
      <label class="email-title ml-10 mb-5">
        You can add the images for events images.
      </label>
      <div class="d-flex ml-10" style="height: 100%; overflow: hidden">
        <v-row style="width: 100%; height: 100%; overflow-y: auto">
          <v-col
            v-for="category in homeImages"
            :key="category._id"
            cols="12"
            sm="6"
          >
            <div
              class="pa-3 category-item d-flex flex-row align-center"
              :style="
                category._id == selected
                  ? 'background: #f3e9ff; justify-content: space-between;'
                  : ''
              "
              @click="onSelectHomeImage(category)"
            >
              <div style="position: relative; width: 100%">
                <v-img :src="category.image" height="300" max-width="1000px" />
                <div style="position: absolute; top: 20px; right: 20px;">
                  <v-btn
                    fab
                    x-small
                    v-if="category._id == selected"
                    @click="onDeleteHomeImage(category)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div style="display:flex; justify-content:flex-end; flex-direction: row;">
        <v-btn
          rounded
          width="200"
          color="primary"
          class="mr-3 my-5"
          @click="openNewHomeImageDialog"
          :loading="loading"
          v-text="'Add New Home Image'"
        />
      </div>
    </v-layout>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog v-model="addHomeImageDialog" width="500px" persistent>
      <v-card>
        <v-card-title style="color: #6e3dc6; font-family: 'Poppins-Bold'; "
          >Add New Home Image</v-card-title
        >
        <v-card-text
          @click="onSelectImage"
          style="position: relative; width: 100%"
        >
          <div>
            <v-img
              :error-messages="newHomeImageMessage"
              :src="new_image ? new_image : placeholderImage"
              height="300"
              width="100%"
              cover
            />
          </div>
          <div
            style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
          >
            <v-btn icon fab><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn
            @click="onAddNewHomeImage"
            rounded
            color="primary"
            small
            :loading="loading"
            >Add</v-btn
          >
          <v-btn @click="addHomeImageDialog = false" rounded small
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseDialog"
      :onConfirm="deleteAction"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog.vue";
export default {
  components: { ConfirmDeleteDialog },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
      values: [],
      selected: null,
      selectedFile: null,
      addHomeImageDialog: false,
      deleteDialog: false,
      newHomeImageMessage: null,
      new_image: null,
      placeholderImage: require("@/assets/default_image.png"),
    };
  },
  computed: {
    ...mapGetters("variable", {
      homeImages: "getAllHomeImages",
    }),
  },
  methods: {
    ...mapActions("variable", {
      fetchAllHomeImages: "fetchAllHomeImages",
      addHomeImage: "addHomeImage",
      deleteHomeImage: "deleteHomeImage",
    }),
    onUpdate() {
      if (this.newPassword != this.confirmPassword) {
        this.errorMessage = "Password does not match";
        this.snackbar = true;
        return;
      }
      var params = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };

      this.loading = true;
      this.updatePassword(params)
        .then(() => {
          this.loading = false;
          this.showErrorMessage("Password updated successfully");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    openNewHomeImageDialog() {
      this.addHomeImageDialog = true;
      this.newHomeImageMessage = null;
    },
    onAddNewHomeImage() {
      if (!this.selectedFile) {
        this.showErrorMessage("Please select a image.");
      }
      this.loading = true;
      this.newHomeImageMessage = null;
      var formData = new FormData();
      formData.append("image", this.selectedFile);

      this.addHomeImage(formData)
        .then(() => {
          this.loading = false;
          this.selectedFile = null;
          this.new_image = "";
          this.addHomeImageDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onChange() {
      this.errorMessage = null;
    },
    onCloseDialog() {
      this.deleteDialog = false;
      this.addHomeImageDialog = false;
      this.new_image = "";
      this.selectedFile = null;
    },
    onSelectHomeImage(category) {
      this.selected = category._id;
    },
    onSelectValue(value) {
      this.selectedValue = value._id;
    },
    onDeleteHomeImage() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.deleteHomeImage({ _id: this.selected })
        .then(() => {
          this.loading = false;
          this.onCloseDialog();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    onSelectImage() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      console.log(e.target.files);
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        this.new_image = URL.createObjectURL(this.selectedFile);
      }
    },
  },
  mounted() {
    this.loading = true;
    this.fetchAllHomeImages()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.email-title {
  color: #6e3dc6;
  font-family: "Poppins-Medium";
  font-size: 15px;
}
.category-item {
  width: 100%;
}
.category-item:hover {
  background: #ebf0f6;
}
.selected {
  background: #abafb3;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
